import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import SyncIcon from '@mui/icons-material/Sync';
import { Box, CircularProgress, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import * as React from 'react';
import Loader from '../../../../shared/components/Loader';
import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';
import { getValueFromLs } from '@/shared/services/localstorage';
import { useGetMediaLibraryImagesQuery } from '../../../services/http/media-library.api';
import { EDITOR_LS_CONSTANTS } from '../../../shared/constants/editor-LS.constants';
import MediaUploader from '../MediaUploader';
import MediaLibraryImageItem from './MediaLibraryImageItem';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { AvailablePlatformsEnum, PlatformType } from '@/editor/shared/constants/available-platforms';
import {
  addedMediaOnDynamic,
  setMediaOnCarouselItem,
  setMediaOnSingle,
} from '@/editor/store/reducers/creative-step.slice';
import toast from 'react-hot-toast';
import CreativeTypesEnum from '@/editor/shared/constants/creatives-types.enum';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import ImagesUploader from './ImagesUploader';

export interface IImageItemModel extends IMediaLibraryImageModel {
  cropped: boolean;
  urlToCropped: string;
  forPlatforms: PlatformType[];
}

const MediaLibraryImages: React.FC = (): JSX.Element => {
  const [allImages, setAllImages] = React.useState<IImageItemModel[]>([]);
  const [externalImages, setExternalImages] = React.useState<IImageItemModel[]>([]);
  const [tabValue, setTabValue] = React.useState(1);
  const [uploadedImages, setUploadedImages] = React.useState<IImageItemModel[]>([]);
  const drawerState = useAppSelector((state) => state.editorModalsDrawersReducer.mediaLibraryDrawerState);
  const dispatch = useAppDispatch();
  const recentlyCroppedImageId = useAppSelector((state) => state.creativeStepSliceReducer.recentlyCroppedImageId);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) return;
    setTabValue(newValue);
  };

  const addImageToCreative = (platform: PlatformType, image: IMediaLibraryImageModel) => {
    switch (drawerState.creativeType) {
      case CreativeTypesEnum.CAROUSEL:
        updateCarouselItemImageIfNeeded(platform, image);
        break;
      case CreativeTypesEnum.DYNAMIC:
        dispatch(addedMediaOnDynamic({ media: image, platform }));
        break;
      case CreativeTypesEnum.SINGLE:
        dispatch(setMediaOnSingle({ media: image, platform }));
        break;
    }
  };

  // const removeImageFromCreative = (platform: PlatformType, image: IMediaLibraryImageModel) => {
  //   switch (drawerState.creativeType) {
  //     case CreativeTypesEnum.DYNAMIC:
  //       dispatch(removeMediaOnDynamic({ platform, media: image }));
  //       break;
  //     case CreativeTypesEnum.SINGLE:
  //       dispatch(removeMediaOnSingle({ platform }));
  //       break;
  //     case CreativeTypesEnum.CAROUSEL:
  //       updateCarouselItemImageIfNeeded(platform, null);
  //       break;
  //   }
  // };

  const updateCarouselItemImageIfNeeded = (
    platformOption: PlatformType,
    media: IMediaLibraryImageModel | null
  ): void => {
    if (drawerState.carouselItemIndex !== undefined) {
      dispatch(
        setMediaOnCarouselItem({
          media,
          index: drawerState.carouselItemIndex,
          platform: platformOption as PlatformType,
        })
      );
    }
    if (media === null && drawerState.carouselItemIndex !== undefined) {
      toast.error('This images is already selected!');
    }
  };

  const {
    data,
    isLoading,
    isFetching,
    refetch: mediaLibraryImagesRefetch,
  } = useGetMediaLibraryImagesQuery(
    { propertyId: getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID)! },
    { skip: !getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID) }
  );

  const { t } = useTranslation();

  useEffect(() => {
    let imageList = data ? [...data] : [];

    let imageItemList: IImageItemModel[] = imageList.map((item) => {
      return {
        ...item,
        cropped: false,
        urlToCropped: '',
        forPlatforms: [],
      };
    });

    const uniqueInArr1 = imageItemList.filter((obj1) => !allImages.some((obj2) => obj1['id'] === obj2['id']));
    setAllImages(imageItemList);

    if (uniqueInArr1.some((image) => image['id'] === recentlyCroppedImageId)) {
      addImageToCreative(
        AvailablePlatformsEnum.SNAPCHAT,
        uniqueInArr1.find((image) => image['id'] === recentlyCroppedImageId) as IMediaLibraryImageModel
      );
      setTabValue(2);
    }

    setExternalImages(imageItemList.filter((item) => item.originalUrl !== 'no_original_url' && item.sourceId === null));

    let uploadedImages: IImageItemModel[] = imageItemList?.filter((item) => {
      if (item.originalUrl === 'no_original_url' || item.sourceId !== null) {
        return {
          ...item,
          cropped: false,
          urlToCropped: '',
          forPlatforms: [],
        };
      }
    });

    setUploadedImages(uploadedImages);
  }, [data]);

  return (
    <div>
      <Box sx={{ width: '100%', pl: 1, pr: 1 }}>
        {/* <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
          <IconButton onClick={mediaLibraryImagesRefetch}>
            <SyncIcon></SyncIcon>
          </IconButton>
        </Box> */}
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <Box>
              {data && data.length >= 0 && (
                <Box>
                  <Box
                    justifyContent={'center'}
                    sx={{ display: 'flex', width: '100%', bgcolor: 'background.paper', borderBottom: '1px solid' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} centered>
                      <Tab label={t('externalImages')} value={1} />
                      <Tab label={t('uploadedCroppedImages')} value={2} />
                      <Tab
                        onClick={mediaLibraryImagesRefetch}
                        icon={isFetching ? <CircularProgress size={20} /> : <SyncIcon />}
                        value={0}
                        aria-label="refresh"
                      />
                    </Tabs>
                    <ImagesUploader refetchAction={mediaLibraryImagesRefetch} />
                  </Box>
                  <Box>
                    <div hidden={tabValue !== 1}>
                      <ImageList cols={3} gap={8} variant="masonry">
                        {externalImages!.map((item, key) => (
                          <div key={key}>
                            <MediaLibraryImageItem queue={key} item={item} />
                          </div>
                        ))}
                      </ImageList>
                    </div>
                    <div hidden={tabValue !== 2}>
                      <ImageList cols={3} gap={8} variant="masonry">
                        {uploadedImages!.map((item, key) => (
                          <div key={key}>
                            <MediaLibraryImageItem queue={key} item={item} />
                          </div>
                        ))}
                      </ImageList>
                    </div>
                  </Box>
                </Box>
              )}
            </Box>
          </div>
        )}
      </Box>
    </div>
  );
};

export default MediaLibraryImages;
